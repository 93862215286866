<template>
  <div  class="body">
    <div class="authentication">
    </div>
    <div class="scan-img">
      <van-image
          class="scan-img"
          :src="bgUrl"
      ></van-image>
    </div>
    <div style="margin-top:10px;height:500px;text-align: center">
       <h3>{{this.time}}秒后将关闭页面</h3>
    </div>
  </div>
</template>

<script>
import request from "@/api";

export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
      timer:null,
      time:5
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/auth_success.png")
    },
    postureUrl(){
      return require("@/assets/img/posture.png")
    }
  },
  watch: {
  },
  created() {
    let data={
      clientId:this.$route.query.clientId,
      cipherText:this.$route.query.cipherText
    }
    request.get(process.env.VUE_APP_PX_URL+'/isdn/license/callback',{ params: data }).then(res=>{
      //this.$toast.success("")
    })
    this.countDown()
  },
  mounted() {

  },
  onLoad() {

  },
  methods: {
    countDown(){
      this.timer = setInterval(() => {
        if (this.time >=1) {
          this.time--
        }else{
          window.history.back(-1)
          clearInterval(this.timer)
        }
      }, 1000)
    },
  },
}
</script>
<style lang='scss' scoped>
.body{
  background-color: white;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
.authentication{
  text-align: center;
  padding-top:120px;
  font-size: 25px;
  font-weight: bold;
}
.scan-img{
  opacity: 0.9;
  width: 10rem;
  height:10rem;
  margin: auto;
}
.posture{
  opacity: 0.9;
  width: 20rem;
  height:10rem;
  margin: auto;
}
.button{
  margin-top:30px;
}
</style>
